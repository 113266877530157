import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#000000',
    position: 'relative',
    zIndex: 1000,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logo: {
    fontWeight: 'normal',
    cursor: 'pointer',
    fontSize: '24px',
    padding: '20px 30px 20px 0',
    backgroundColor: 'transparent',
    border: 'none',
    color: 'white',
    '&:focus': {
      outline: 'none',
    },
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  controlItem: {
    height: '100%',
    border: 'none',
    color: 'white',
    backgroundColor: 'transparent',
    fontSize: '18px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 20,
    '& button': {
      textTransform: 'none',
      padding: '8px 14px 8px 11px',
    },
    '& span': {
      // marginRight: 10,
    },
    '& svg': {
      marginRight: 10,
    },
  },
  controlItemContainer: {
    margin: '0 5px',
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  search: {
    position: 'relative',
    backgroundColor: '#121619',
    padding: 40,
    zIndex: 1000,
  },
  searchWrap: {
    marginTop: 16,
  },
  email: {
    marginLeft: 30,
  },
}));

export default useStyles;
